import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from 'src/components/SEO';
import PlatformPage from 'src/components/pages/PlatformPage';

const Platform = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <SEO title={frontmatter.title} />
      <PlatformPage
        introduction={frontmatter.introduction}
        tabsData={frontmatter.tabs}
        platformFeature={frontmatter.platformFeature}
      />
    </>
  );
};

Platform.propTypes = {
  data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query PlatformPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "platform-page" } }) {
      frontmatter {
        title
        introduction {
          heading
          text
          title
        }
        tabs {
          content {
            heading
            image {
              alt
              imgSrc {
                childImageSharp {
                  gatsbyImageData(width: 200, placeholder: BLURRED)
                }
              }
            }
            text
          }
          title
        }
        platformFeature {
          heading
          text
          image {
            alt
            imgSrc {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
export default Platform;
