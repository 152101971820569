import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const TabContainer = tw.div`flex px-24 items-start border-b border-gold space-x-2`;
const TabButton = styled.button`
  ${tw`flex-grow focus:outline-none text-navy-light text-xl font-bold py-6`}
  ${({ active }) => active && tw`border-b-8 border-gold`}
`;
const TabContentContainer = tw.div`px-24`;

const Tabs = ({ children, className }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={className}>
      <TabContainer>
        {children.map((child, index) => {
          if (!child.props.title) return null;
          return (
            <TabButton
              role="tab"
              key={index}
              active={index === activeTab}
              onClick={() => {
                setActiveTab(index);
              }}
            >
              {child.props.title}
            </TabButton>
          );
        })}
      </TabContainer>

      <TabContentContainer>{children[activeTab]}</TabContentContainer>
    </div>
  );
};

Tabs.Tab = tw.div``;

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Tabs;
