import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import BaseHalf from 'src/components/Half';
import BaseTabs from 'src/components/Tabs';
import BaseHeading from 'src/components/Heading';
import Image from 'src/components/Image';

const Half = tw(BaseHalf)`mb-24`;
const PlatformConnect = tw(BaseHalf)`px-4 sm:px-32 mb-64`;
const PlatformConnectContent = tw(BaseHalf.Content)`space-y-6`;
const Heading = tw(BaseHeading)`leading-12`;
const TabContentHeading = tw(Heading)`text-lg mb-7`;
const Tabs = tw(BaseTabs)`bg-gray-200 mb-60`;
const Tab = tw(BaseTabs.Tab)`px-2 pt-40 pb-52`;

const PlatformIntro = tw.section`px-4 space-y-8 mb-48 mt-16`;
PlatformIntro.Title = tw.p`text-xl font-bold text-center text-navy-light`;
PlatformIntro.Heading = tw(Heading)`mx-auto sm:w-140`;
PlatformIntro.Paragraph = tw.p`mx-auto sm:w-140`;

const PlatformPage = ({ introduction, tabsData, platformFeature }) => {
  const getTabContent = (content) => (
    <Half key={content.heading}>
      <Half.Content>
        <TabContentHeading>{content.heading}</TabContentHeading>
        <p>{content.text}</p>
      </Half.Content>
      <Half.Content>
        <Image src={content.image.imgSrc} alt={content.image.alt} />
      </Half.Content>
    </Half>
  );

  return (
    <>
      <PlatformIntro>
        <PlatformIntro.Title>{introduction.title}</PlatformIntro.Title>
        <PlatformIntro.Heading>{introduction.heading}</PlatformIntro.Heading>
        <PlatformIntro.Paragraph>{introduction.text}</PlatformIntro.Paragraph>
      </PlatformIntro>
      <Tabs>
        {tabsData.map((tab) => (
          <Tab key={tab.title} title={tab.title}>
            {tab.content.map(getTabContent)}
          </Tab>
        ))}
      </Tabs>
      <PlatformConnect>
        <PlatformConnectContent>
          <Heading>{platformFeature.heading}</Heading>
          <p>{platformFeature.text}</p>
        </PlatformConnectContent>
        <Half.Content>
          <Image
            src={platformFeature.image.imgSrc}
            alt={platformFeature.image.alt}
          />
        </Half.Content>
      </PlatformConnect>
    </>
  );
};

PlatformPage.propTypes = {
  introduction: PropTypes.object.isRequired,
  tabsData: PropTypes.array.isRequired,
  platformFeature: PropTypes.object.isRequired
};

export default PlatformPage;
